import React, { useEffect, useState } from "react";
import "../homepage.css";
import Logo from "../image/THOUGHTCODES-logo.png";
import heroImage from "../image/agile-methodology_blue.png";
import certificateImg1 from "../image/SBA_certification.png";
import certificateImg2 from "../image/certificate-SWAM.png";
import certificateImg3 from "../image/GSA_certification.png";
import capabilitiesImg1 from "../image/capabilities-agile-development.png";
import capabilitiesImg2 from "../image/capabilities-Cyber-Security.png";
import capabilitiesImg3 from "../image/Cloud-icon.png";
import capabilitiesImg4 from "../image/capabilities-devops.png";
import capabilitiesImg5 from "../image/capabilities-app-development.png";
import getToKnowImg from "../image/image1.png";
import getToKnowImg2 from "../image/image2.png";
import getToKnowImg3 from "../image/image3.png";
import partnerImg1 from "../image/partner-microsoft.png";
import partnerImg2 from "../image/partner-inGram-Micro.png";
import getInTouchImg from "../image/getInTouchImg.png";
import facebookIcon from "../image/icon-facebook.png";
import instagramIcon from "../image/icon-instagram.png";
import linkedinIcon from "../image/icon-linkedin.png";
import twitterIcon from "../image/icon-twitter.png";
import callIcon from "../image/call.png";
import mailIcon from "../image/mail.png";
import { useFormik } from "formik";
import axios from "axios";
import * as yup from "yup";

const FormSchema = yup.object().shape({
  name: yup.string().required("Name Should be Required"),
  email: yup
    .string()
    .required("Email address Required")
    .email("Valid email required"),
  phone_number: yup.string().required("Phone Number Should be Required"),
  business_type: yup.string().required("Business Type Should be Required"),
  message: yup.string().required("Message Should be Required"),
});

function HomePage() {
  const [showTopbar, setShowTopbar] = useState(true);
  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    document.getElementById("top-header")?.classList.remove("small-header");
    document.getElementById("top-header")?.classList.add("home-header");
  }, []);

  function handleScroll() {
    if (document.documentElement.scrollTop > 0) {
      document.getElementById("top-header")?.classList.add("small-header");
      document.getElementById("top-header")?.classList.remove("home-header");
    } else {
      document.getElementById("top-header")?.classList.remove("small-header");
      document.getElementById("top-header")?.classList.add("home-header");
    }
  }

  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    phone_number: "",
    business_type: "",
    message: "",
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: FormSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let response: any = await axios.post(
          "http://103.232.124.169:11115/api/v1/contact-us",
          values
        );
        resetForm();
      } catch (error: any) {
        console.log("error:", error.response.data.message);
      }
    },
  });
  const formikSubscribe = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required("Email address Required")
        .email("Valid email required"),
    }),
    onSubmit: async (values: { email: string }, { resetForm }) => {
      try {
        let response: any = await axios.post(
          "http://103.232.124.169:11115/api/v1/subscribe",
          values
        );
        resetForm();
      } catch (error: any) {
        console.log("error:", error.response.data.message);
      }
    },
  });

  return (
    <>
      <div className="sticky" style={{ zIndex: 999 }} hidden={!showTopbar}>
        <header id="top-header" className="home-header">
          <nav className="navbar navbar-expand-lg bg-light">
            <div className="container">
              <div className="header-logo" id="big-header-logo">
                <img src={Logo} />
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="headerMenu">
                  <ul>
                    <li>
                      <div className="active menu-list-name" role="button">
                        <a href="#home">Home</a>
                      </div>
                    </li>
                    <li>
                      <div className="menu-list-name" role="button">
                        <a href="#about">About</a>
                      </div>
                    </li>
                    <li>
                      <div className="menu-list-name" role="button">
                        <a href="#capabilities">Capabilities</a>
                      </div>
                    </li>
                    <li>
                      <div className="menu-list-name" role="button">
                        <a href="#contact-us">Contact Us</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
      <div data-spy="scroll" data-target="#navbar-example2" data-offset="0">
        <div className="heroSection" id="home">
          <div className="heroContent">
            <div className="lineSmall"></div>
            <h3>ThoughtCodes</h3>
            <h1>
              Your vision, <span className="animation-text">Our creation</span>
            </h1>
            <div className="lineBig"></div>
          </div>
          <img src={heroImage} className="img-fluid heroIllustration"/>
        </div>
        <div className="certificationSection">
          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="d-flex flex-column align-items-xl-start align-items-md-center">
                  <div className="sectionHeading">
                    NAICS Codes and Our Certification
                  </div>
                  <div className="certificationCodes text-md-center text-xl-start">
                    511210, 517210, 5197919, 541330, 541511, 541512, 561210,
                    541513, 517110,517410, 518210, 541330, 541512, 541990,
                    811212, 541519, 541611, 541690, 561621
                  </div>
                </div>
              </div>
              <div className="col-xl-8">
                <div className="d-flex flex-column flex-sm-row justify-content-xl-end justify-content-md-center mt-xl-0 mt-5">
                  <div className="certificateImgBg mb-4 mb-sm-0">
                    <img src={certificateImg3} />
                  </div>
                  <div className="certificateImgBg mb-4 mb-sm-0">
                    <img src={certificateImg1} />
                  </div>
                  <div className="certificateImgBg">
                    <img src={certificateImg2} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="middleSection" id="capabilities">
          <div className="section-space"></div>
          <div className="capabilitiesSection">
            <div className="CapabilitiesHeading text-center">
              ThoughtCodes
              <span className="theme-text-color">Capabilities</span>
            </div>
            <div className="container">
              <div className="row tab-row">
                <div className="col tab-col">
                  <div className="text-center capabilitiesImg">
                    <img src={capabilitiesImg1} />
                  </div>
                  <div className="capabilitiesText">Agile</div>
                </div>
                <div className="col tab-col">
                  <div className="text-center capabilitiesImg">
                    <img src={capabilitiesImg2} />
                  </div>
                  <div className="capabilitiesText">Cyber Security</div>
                </div>
                <div className="col tab-col">
                  <div className="text-center capabilitiesImg">
                    <img src={capabilitiesImg3} />
                  </div>
                  <div className="capabilitiesText">Cloud</div>
                </div>
                <div className="col tab-col">
                  <div className="text-center capabilitiesImg">
                    <img src={capabilitiesImg4} />
                  </div>
                  <div className="capabilitiesText">DevSecOps</div>
                </div>
                <div className="col tab-col">
                  <div className="text-center capabilitiesImg">
                    <img src={capabilitiesImg5} />
                  </div>
                  <div className="capabilitiesText">App Development</div>
                </div>
              </div>
            </div>
          </div>
          <div className="getToKnowSection" id="about">
            <div className="section-space"></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="text-center getToKnowImg">
                    <img src={getToKnowImg} className="img-fluid w-100" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="getToKnowContent ms-lg-5 mt-3 mt-md-4 mt-lg-0">
                    <div className="CapabilitiesHeading mb-4">
                      Get To 
                      <span className="theme-text-color">Know Us</span>
                    </div>
                    <div className="getToKnowContent-text">
                      We are a software development company specializing in App
                      Development, Agile, Cyber Security, Cloud and DevSecOps.
                      <br></br>
                      <br></br> Our selective use of cutting-edge technologies,
                      passion for innovation, and unique adaptation of agile and
                      user-centered design processes allow us to simplify our
                      code, architectures, and designs, resulting in faster
                      deployments with a “clean” look, natural feel, and an
                      improved user experience.<br></br>
                      <br></br> Our project teams are lean, agile, and get the
                      job done! Our knowledge of both the business and technical
                      sides is the source of continuous innovation to meet the
                      ever-increasing demand for better software delivered
                      faster.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="getToKnowSection">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 order-2 order-lg-1">
                  <div className="getToKnowContent me-lg-5 mt-3 mt-md-4 mt-lg-0">
                    <div className="CapabilitiesHeading mb-4">
                      Our Core
                      <span className="theme-text-color">Values</span>
                    </div>
                    <div className="getToKnowContent-text">
                      <b>Integrity:</b> We adhere to a strict code of
                      truthfulness and honesty in all business interactions to
                      ensure consistency in our values, actions, expectations,
                      and outcomes.
                      <br></br>
                      <br></br>
                      <b>Responsive:</b>  We work hard to ensure we understand
                      the customer's needs and respond quickly to meet them.
                      <br></br>
                      <br></br>
                      <b>Innovative:</b>  We think "outside the box" and
                      judiciously apply contemporary technologies to create
                      better solutions.
                      <br></br>
                      <br></br>
                      <b>Cost effective:</b>  Our lean organization and fair
                      pricing provide outstanding value as evidenced by our many
                      long-term and repeat customers.
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="text-center getToKnowImg">
                    <img src={getToKnowImg2} className="img-fluid w-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="getToKnowSection">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="text-center getToKnowImg">
                    <img src={getToKnowImg3} className="img-fluid w-100" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="getToKnowContent me-lg-5 mt-3 mt-md-4 mt-lg-0">
                    <div className="CapabilitiesHeading mb-4">
                      Come to join
                      <span className="theme-text-color">our team</span>
                    </div>
                    <div className="getToKnowContent-text">
                      <b>Are you ready to take the next leap in your career?</b>
                      <br></br>
                      <br></br>
                      Come join our team of top IT professionals. Whether you
                      enjoy programming, building beautiful UIs, or leading
                      Agile projects, you'll have a place to develop your craft
                      and grow as a professional.
                    </div>
                    <div className="mt-md-5 mt-3">
                      <button className="theme-btn-black ps-5 pe-5">
                        <a href="#contact-us">Join our team</a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="getToKnowSection">
            <div className="container">
              <div className="row">
                <div className="col-xl-6">
                  <div className="d-flex flex-column align-items-xl-start align-items-md-center">
                    <div className="CapabilitiesHeading">
                      Our
                      <span className="theme-text-color">partners</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div
                    className="d-flex flex-column flex-sm-row 
                  justify-content-xl-end justify-content-md-center 
                  mt-xl-0 mt-lg-5"
                  >
                    <div className="partnerImgBg mb-4 mb-sm-0">
                      <img src={partnerImg1} className="img-fluid" />
                    </div>
                    <div className="partnerImgBg">
                      <img src={partnerImg2} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="getInTouch" id="contact-us">
          <div className="d-flex flex-wrap">
            <div className="col-lg-6 tab-w-100">
            <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3109.330224884455!2d-77.07476092378138!3d38.80198615232781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b1a42292a1cd%3A0x18ddf2f5b40294ab!2s2461%20Eisenhower%20Ave%2C%20Alexandria%2C%20VA%2022331%2C%20USA!5e0!3m2!1sen!2sin!4v1682585988376!5m2!1sen!2sin" 
             loading="lazy" style={{ width: "100%", height: "100%", minHeight: "300px" }}>

            </iframe>
              {/* <img src={getInTouchImg} className="img-fluid getInTouchImg" /> */}
            </div>
            <div className="col-lg-6">
              <div className="getInTouchContent">
                <div className="CapabilitiesHeading text-white mb-4">
                  Let's Talk
                  <span className="theme-text-color">With Us</span>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="formInput">
                        <input
                          className="form-control w-100"
                          placeholder="Your Name"
                          type="text"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.name && formik.touched.name && (
                          <p className="alert-text">{formik.errors.name}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="formInput">
                        <input
                          type="email"
                          className="form-control w-100"
                          placeholder="Your Email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <p className="alert-text">{formik.errors.email}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="formInput">
                        <input
                          type="number"
                          className="form-control w-100"
                          placeholder="Phone Number"
                          name="phone_number"
                          value={formik.values.phone_number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.phone_number &&
                          formik.touched.phone_number && (
                            <p className="alert-text">
                              {formik.errors.phone_number}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="formInput">
                        <input
                          type="text"
                          className="form-control w-100"
                          placeholder="Business Type"
                          name="business_type"
                          value={formik.values.business_type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.business_type &&
                          formik.touched.business_type && (
                            <p className="alert-text">
                              {formik.errors.business_type}
                            </p>
                          )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="formInput">
                        <textarea
                          className="form-control w-100"
                          placeholder="Message Type Here"
                          name="message"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        ></textarea>
                        {formik.errors.message && formik.touched.message && (
                          <p className="alert-text">{formik.errors.message}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <button
                        className="theme-btn-blue ps-5 pe-5"
                        type="submit"
                      >
                        Send Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="footerContent">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-8">
                <div className="FooterMenu">
                  <ul>
                    <li>
                      <div className="active menu-list-name" role="button">
                        <a href="#home">Home</a>
                      </div>
                    </li>
                    <li>
                      <div className="menu-list-name" role="button">
                        <a href="#about">About</a>
                      </div>
                    </li>
                    <li>
                      <div className="menu-list-name" role="button">
                        <a href="#capabilities">Capabilities</a>
                      </div>
                    </li>
                    <li>
                      <div className="menu-list-name" role="button">
                        <a href="#contact-us">Contact Us</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-4">
                <div className="socialMediaIcon d-flex align-items-center justify-content-md-end justify-content-center mb-2 mb-md-0">
                  <div className="ms-3">
                    <a
                      href="https://www.linkedin.com/company/thoughtcodes-llc/"
                      target="_blank"
                    >
                      <img src={linkedinIcon} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyRightSection">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-lg-start">
                  <p className="m-0 me-sm-5 ">
                    Copyright © 2023 Thought Code™. All rights reserved.
                  </p>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="footerListWrapper">
                  <div className="footerList ps-md-5 ws-no-wrap">
                    <img src={mailIcon} />
                    <div className="ms-2">
                      <a href="mailto:info@thoughtcodes.com">
                        info@thoughtcodes.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
